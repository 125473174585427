import {isEmpty, Nullable} from '../type-helper';

export type CustomEvent = CustomEventCreateBestellung | CustomEventImportFromAuftrag | CustomEventCreateBestellungButtonInMuster | CustomEventNotificationCountChanged;

enum CustomEvents {
  CREATE_BESTELLUNG = 'createBestellung',
  IMPORT_FROM_AUFTRAG = 'importFromAuftrag',
  CREATE_BESTELLUNG_BUTTON_IN_MUSTER = 'clickCreateBestellungInMuster',
  NOTIFICATION_COUNT_CHANGED = 'notificationCountChanged'
}

export class CustomEventCreateBestellung {
  type = CustomEvents.CREATE_BESTELLUNG;

  constructor(
    private readonly attributes: {
      ist_wlv: boolean;
      alter_bestellung?: Nullable<number>;
      aus_bestellung: boolean;
      aus_muster: boolean;
      view: string;
    }
  ) {}

  public get eventMetaData(): {[key: string]: string | number | boolean} {
    return {
      ist_wlv: this.attributes.ist_wlv,
      alter_bestellung: quantize(this.attributes.alter_bestellung, ' Tage') ?? 'undefined',
      aus_bestellung: this.attributes.aus_bestellung,
      aus_muster: this.attributes.aus_muster,
      view: this.attributes.view
    };
  }
}

export class CustomEventImportFromAuftrag {
  type = CustomEvents.IMPORT_FROM_AUFTRAG;

  constructor(
    private readonly attributes: {
      alter_auftrag: number | undefined;
    }
  ) {}

  public get eventMetaData(): {[key: string]: string | number | boolean} {
    return {
      alter_auftrag: quantize(this.attributes.alter_auftrag, ' Tage') ?? 'undefined'
    };
  }
}

export class CustomEventCreateBestellungButtonInMuster {
  type = CustomEvents.CREATE_BESTELLUNG_BUTTON_IN_MUSTER;
  public eventMetaData = {};
}

export class CustomEventNotificationCountChanged {
  type = CustomEvents.NOTIFICATION_COUNT_CHANGED;

  constructor(
    private readonly attributes: {
      source: 'removeOne' | 'removeAll' | 'addOne';
      newCount: number;
    }
  ) {}

  public get eventMetaData(): {[key: string]: string | number | boolean} {
    return {
      source: this.attributes.source,
      newCount: quantize(this.attributes.newCount, ' Notifications')
    };
  }
}

function quantize(value: number, postfix: string, cutoffs?: number[]): string;
function quantize(value: Nullable<number>, postfix: string, cutoffs?: number[]): string | null;
function quantize(value: Nullable<number>, postfix: string, cutoffs = [1, 3, 7, 14, 30, 90]): string | null {
  if (isEmpty(value)) {
    return null;
  }
  const sign = value < 0 ? '-' : '';
  const absValue = Math.abs(value);
  for (let i = 0; i < cutoffs.length; i++) {
    const cutoff = cutoffs[i];
    if (absValue <= cutoff) {
      return `${sign}${cutoffs[i - 1] ?? 0}-${cutoff}${postfix}`;
    }
  }
  return `${sign}${cutoffs[cutoffs.length - 1]}+${postfix}`;
}
